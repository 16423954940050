import { Container, Grid, Image, Button } from 'semantic-ui-react'
import './Footer.css'
import { Link } from 'react-router-dom'
import { rightArrow } from '../../../svg.js'

function Footer() {
	return (
		<>
			{/* Old footer */}
			{/* <Container>
				<div className='footer'>
					<div className='bg-brand pt-48 pb-48'>
						<div className='bg-brand' style={{ width: '736px', margin: '0 auto' }}>
							<h3 className='mb-40'>
								If this information is helpful to you, please consider making a quick
								donation today because every penny counts. 100% of donations go to
								support and research.
							</h3>

							<a
								href='https://www.paypal.com/donate/?hosted_button_id=8X42KWEQLC75L'
								target='_blank'
								rel='noreferrer'
							>
								<input
									type='image'
									src='https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif'
									border='0'
									name='submit'
									title='PayPal - The safer, easier way to pay online!” alt=“Donate with PayPal button'
									alt='Paypal Button'
								/>
								<img
									alt=''
									border='0'
									src='https://www.paypal.com/en_US/i/scr/pixel.gif'
									width='1'
									height='1'
								/>
							</a>
						</div>
					</div>
				</div>
				<div className='PXE-hr'></div>
				<p className='ui center aligned container'>
					26400 Woodfield Road #189, Damascus MD 20872 | Tel: 202.362.9599 x208
					<br></br>
					Except where otherwise noted, content on this site is licensed under a{' '}
					<a rel='license' href='http://creativecommons.org/licenses/by-nc-sa/4.0/'>
						Creative Commons Attribution-NonCommercial-ShareAlike 4.0 International
						License
					</a>
					.
				</p>
				<div className='footer-navigation three large ui buttons'>
					<Link className='ui basic button' to='/'>
						Home
					</Link>
					<Link className='ui basic button' to='/privacy-policy'>
						Privacy Policy
					</Link>
					<Link className='ui basic button' to='/health-disclaimer'>
						Health Disclaimer
					</Link>
				</div>
			</Container> */}


			<div className='footer-background'>
				<Container className='footer-content'>
					<Grid columns={2} stackable reversed='mobile'>
						<Grid.Column>
							<Grid.Row>
								<Link className='inline-block' to='/'>
									<Image
										src='/images/structure/logo.png'
										className='footer-logo'
										alt='PXE International, Inc.'
									/>
								</Link>
							</Grid.Row>
							<Grid className='footer-contact' columns={2} stackable>
								<Grid.Column>
									<p className='body-small'>202.362.9599</p>
									<p className='body-small'>26400 Woodfield Road #189<br/>Damascus MD 20872</p>
								</Grid.Column>
								<Grid.Column>
									{<p>
										<Link className='body-small-link' to='/contact-us' rel='noreferrer'>
											Contact
										</Link>
									</p>}
									<p>
										<Link className='body-small-link' to='/privacy-policy' rel='noreferrer'>
											Privacy Policy
										</Link>
									</p>
									<p>
										<Link className='body-small-link' to='/health-disclaimer' rel='noreferrer'>
											Health Disclaimer
										</Link>
									</p>
								</Grid.Column>
							</Grid>
						</Grid.Column>
						<Grid.Column>
							<p className='title-default' style={{ marginBottom: '10px' }}>Join the community</p>
							<p className='body-default'>You will receive the monthly eNews and information about clinical trials and have the opportunity to meet other PXEers.</p>
							<a href='https://docs.google.com/forms/d/e/1FAIpQLSfrfXWCvcf3NeI5bvMTzFuA8ZHAdCxV2Mnr0tgM6_6ODYigEw/viewform?pli=1' target='_blank' rel='noreferrer'> 
								<Button className='button-tertiary footer-sign-up-button'>Sign up!{rightArrow}</Button> 
							</a>
						</Grid.Column>
					</Grid>
					<p className='body-xsmall mt-15'>Except where otherwise noted, content on this site is licensed under a{' '} 
						<a className='body-xsmall-link' href='https://creativecommons.org/licenses/by-nc-sa/4.0/' target='_blank' rel='noreferrer'>
							Creative Commons Attribution-NonCommercial-ShareAlike 4.0 International License
						</a>.
					</p> {/* mt-15 class is necessary to make the Creative Commons link clickable, for some reason. */}
				</Container>
			</div>
		</>
	)
}

export default Footer
