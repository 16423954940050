import React, { useState, useEffect } from 'react'
import { NavLink, Link } from 'react-router-dom'
import './Navbar.css'
import { Icon } from 'semantic-ui-react'
import { MenuData } from '../navigation/MenuItems'
// import BreadCrumb from '../BreadCrumb'

function Navbar() {
	const [click, setClick] = useState(false)
	const closeMobileMenu = () => setClick(false)
	const [hideMenu, setHideMenu] = useState(false)
	const [previousStack, setPreviousStack] = useState([])
	const [currentMenus, setCurrentMenus] = useState(MenuData)
	const handleClick = () => setClick(!click)
	// const [hideBreadCrumb, setHideBreadCrumb] = useState(false)
	// const location = useLocation().pathname

	/** useEffect(() => {
		if (location === '/') {
			setHideBreadCrumb(true)
		} else {
			setHideBreadCrumb(false)
		}
	}, [location]) **/

	useEffect(() => {
		const timer = setTimeout(() => {
			setHideMenu(false)
		}, 500)
		return () => {
			clearTimeout(timer)
		}
	}, [hideMenu])

	const renderMenuItems = data => {
		return data.map((item, index) =>
			item?.children && item?.children.length ? (
				<li key={index} className='nav-item'>
					<NavLink to={item.path} className='nav-links' activeClassName='active'>
						{item.title} <Icon name='angle down' />
					</NavLink>
					{!hideMenu && (
						<ul onClick={() => setHideMenu(true)}>
							{renderMenuItems(item.children)}
						</ul>
					)}
				</li>
			) : (
				<li key={index} className='nav-item'>
					<NavLink
						to={item.path}
						exact
						className='nav-links'
						activeClassName='active'
					>
						{item.title}
					</NavLink>
				</li>
			)
		)
	}

	const renderMobileMenuItems = data => {
		return data.map((item, index) =>
			item?.children && item?.children.length ? (
				<Link
					key={index}
					onClick={e => {
						previousStack.push(data)
						setPreviousStack(previousStack)
						setCurrentMenus(item.children)
					}}
					to={'#'}
				>
					{item.title} <Icon name='angle down' />{' '}
				</Link>
			) : (
				<Link key={index} to={item.path} onClick={closeMobileMenu}>
					{item.title}
				</Link>
			)
		)
	}

	return (
		<>
			<div className='navbar'>
				<div className='menu-icon' onClick={handleClick}>
					<i className={click ? 'fas fa-times' : 'fas fa-bars'} />
				</div>
				<div className='multilevelMenu hideNav'>
					<ul
						className={
							click ? 'nav-menu active main-navigation' : 'nav-menu main-navigation'
						}
						style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}
					>
						{renderMenuItems(MenuData)}
					</ul>
				</div>
				<div className='multilevelMenu showNav'>
					<ul
						className={
							click ? 'nav-menu active main-navigation' : 'nav-menu main-navigation'
						}
					>
						{previousStack.length ? (
							<Link
								to={'#'}
								onClick={e => {
									const prevState = previousStack.pop()
									setPreviousStack(previousStack)
									setCurrentMenus(prevState)
								}}
							>
								<Icon name='angle left' /> Back
							</Link>
						) : null}
						{renderMobileMenuItems(currentMenus)}
					</ul>
				</div>
			</div>
			{/* {!hideBreadCrumb && <BreadCrumb />} */}
		</>
	)
}

export default Navbar
